import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import logo from '../assets/logo.png';
import { useDispatch, useSelector } from 'react-redux';
import { signIn, reset } from '../redux/slices/AuthSlice';
import '../styles/login.css';

function Login() {
  const [msg, setMsg] = useState(null);
  const { admin, LoginError, message,LoginLoading } = useSelector((state) => state.authData);
  const dispatch = useDispatch();
  useEffect(() => {

    if (!admin && LoginError) {
      setMsg(message);
    }
     dispatch(reset());
  }, [LoginError, message, dispatch, admin]);

  const validationSchema = Yup.object().shape({
    email: Yup.string().required('Le champ de email est obligatoire'),
    password: Yup.string().required('Le champ de password est obligatoire'),
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      await dispatch(signIn(values))
    },
  });

  return (
    <div className="login-container">
      <div className="logo-container vertical-center">
        <img src={logo} alt="" width="200" />
      </div>
      <div className="vertical-center text-center">
            <div className="col-md-6 ">
              <form onSubmit={formik.handleSubmit}>
                <p>Veuillez vous connecter à votre compte</p>
                {msg !== null ? (
                  <div className="alert alert-danger" role="alert">
                    {msg}
                  </div>
                ) : null}
                <div className="form-floating">
                  <input
                    type="email"
                    name="email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    className={
                      formik.errors.email && formik.touched.email
                        ? 'form-control is-invalid'
                        : 'form-control'
                    }
                    id="floatingInput"
                    placeholder="name@example.com"
                  />
                  <label htmlFor="floatingInput">Email</label>
                  <div style={{ color: 'red', fontSize: 12 }}>
                    {formik.errors.email && formik.touched.email ? formik.errors.email : null}
                  </div>
                </div>
                <div className="form-floating">
                  <input
                    type="password"
                    name="password"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    className={
                      formik.errors.password && formik.touched.password
                        ? 'form-control is-invalid'
                        : 'form-control'
                    }
                    id="floatingPassword"
                    placeholder="Password"
                  />
                  <label htmlFor="floatingPassword">Mot de passe</label>
                  <div style={{ color: 'red', fontSize: 12 }}>
                    {formik.errors.password && formik.touched.password ? formik.errors.password : null}
                  </div>
                </div>

                <button className="w-100 btn btn-lg btn-danger" type="submit">
                  {LoginLoading ? "Loading" :"Sign in" }
                </button>
              </form>
            </div>
          </div>
        </div>


  );
}

export default Login;
