import React ,{useState}from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import {addTeacher} from '../api/TeachersApi'
import { toast } from 'react-toastify';
const validationSchema = Yup.object().shape({
  FullName: Yup.string().required('FullName is required'),
  Phone: Yup.string().required('N° Phone is required'),
  password: Yup.string().required('password is password'),


});

const AddTeachersDialog = ({ open, onClose, teachers }) => {
  const [eroroMessage,setEroroMessage]=useState('')
  const handleSubmit = async (values, { setSubmitting }) => {
    addTeacher(values)
        .then((data) => {
          teachers();
            setSubmitting(false);
             onClose();
             toast.success(" Ajout Réussi");

        })
        .catch((error) => {
             setEroroMessage(JSON.parse(error.responseText))
        })
      

    
  };
 
  return (
    <Dialog PaperProps={{ style: { width: 500 }} } open={open} onClose={onClose} >

      <DialogTitle sx={{ padding: '30px',textAlign: 'center' }}>Ajouter Enseignant</DialogTitle>
      <DialogContent>
     { eroroMessage && <h5 className='text-center text-danger'>{eroroMessage?.error}</h5>}
      <Formik
        initialValues={{ FullName: '', Phone: '', password: ''}}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, setFieldValue }) => (
          <Form encType="multipart/form-data">
            <div className="mb-3">
              <label htmlFor="FullName" className="form-label">
                Nom & Prenom
              </label>
              <Field
                type="text"
                id="FullName"
                name="FullName"
                className="form-control"
                autoComplete="off"

              />
              <ErrorMessage name="FullName" component="div" className="text-danger" />
            </div>
            <div className="mb-3">
              <label htmlFor="Phone" className="form-label">
                N° Tel
              </label>
              <Field
                type="text"
                id="Phone"
                name="Phone"
                className="form-control"
                autoComplete="off"

              />
              <ErrorMessage name="Phone" component="div" className="text-danger" />
            </div>
            <div className="mb-3">
              <label htmlFor="password" className="form-label">
                Mot de pass
              </label>
              <Field
                type="text"
                id="password"
                name="password"
                className="form-control"
                autoComplete="off"

              />
              <ErrorMessage name="password" component="div" className="text-danger" />
            </div>
          
              <DialogActions>
                <Button onClick={onClose} color="secondary">
                  Annuler
                </Button>
                <Button type="submit" color="primary" disabled={isSubmitting}>
                  Ajouter
                </Button>
              </DialogActions>
              </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default AddTeachersDialog;

               