import { endPoint } from "../constants/GlobaleVariables"
import axios from 'axios';


export const getEvents = async (page) => {
  try {
    const response = await axios.get(`${endPoint}/api/notification/getEvents?page=${page || 0}`);
    return response.data;
  } catch (error) {
    // console.error('Error fetching products:', error.response.data.message);
    return [];
  }
};


export const addEvents = async (values) => {
  try {
      const response = await axios.post(`${endPoint}/api/notification/addNotification`, values);
      return response.data;
    } catch (error) {
      throw error.response.request
    }

};

export const deleteEvent = async (eventd) => {
  try {
    const response = await axios.delete(`${endPoint}/api/notification/deletevent/${eventd}`);
    return response;
  } catch (error) {
    console.error('An error occurred while deleting the transporter:', error);
  }
};