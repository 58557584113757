import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import Slide from '@mui/material/Slide';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography'; // Import Typography
import styles from '../assets/modelStyle';

const useStyles = makeStyles(styles);

const useStyled = makeStyles(theme => ({
  modalCloseButton: {
        color: 'gray',
        marginTop: '-12px',
        WebkitAppearance: 'none',
        padding: '0',
        cursor: 'pointer',
        background: '0 0',
        border: '0',
        fontSize: 'inherit',
        opacity: '.9',
        textShadow: 'none',
        fontWeight: '700',
        lineHeight: '1',
        float: 'right',
  },
  modalClose: {
    // Define your icon styles here
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function ModalComponent({
  visible,
  close,
  title,
  subTitle,
  maxWidth,
  actions,
  children,
  ...props
}) {
  const classes = useStyles();
  const classe = useStyled();
  return (
    <Dialog
      classes={{
        root: classes.center,
        paper: classes.modal,
      }}
      open={visible}
      TransitionComponent={Transition}
      keepMounted
      onClose={close}
      fullWidth
      aria-labelledby="modal-slide-title"
      aria-describedby="modal-slide-description"
      scroll="body"
      maxWidth={maxWidth || 'md'}
      {...props}
    >
      <DialogTitle
        id="classic-modal-slide-title"
        className={classes.modalHeader}
      >
        <Button
          className={classe.modalCloseButton}
          aria-label="Close"
          color="inherit" // 'inherit' instead of 'transparent'
          onClick={close}
        >
          <CloseIcon className={classe.modalClose} />
        </Button>

        {subTitle ? (
          <div className='mb-5 text-center'>
          <Typography  className={classes.subtitle}>
            {subTitle}
          </Typography>
          </div>
        ) : (
          ''
        )}
        {title ? (
          <Typography  className={classes.modalTitle}>
            {title}
          </Typography>
        ) : (
          ''
        )}
      </DialogTitle>
      <DialogContent id="modal-slide-description" className={classes.modalBody}>
        {children}
      </DialogContent>
      {actions && (
        <DialogActions
          className={`${classes.modalFooter} ${classes.modalFooterCenter}`}
        >
          {actions}
        </DialogActions>
      )}
    </Dialog>
  );
}

ModalComponent.propTypes = {
  // title: PropTypes.string.isRequired,
  visible: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  maxWidth: PropTypes.any,
  actions: PropTypes.any,
  children: PropTypes.any.isRequired,
  //   subTitle: PropTypes.string.isRequired,
};
