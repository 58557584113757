import React, {useState ,useEffect} from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Pagination,
  Typography,
  Grid,
} from '@mui/material';
import "../styles/owner/module.css"
import { getDemandes,changeStatus} from '../api/Demande';
import moment from 'moment';
import { DateRange } from '@mui/icons-material';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function DemandeTable() {
  const [demandes, setDemandes] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(true);

  const DemandesItems  = async ()=>{
    try {
      const data = await getDemandes();
      setDemandes(data.certificate);
      setTotal(data.total);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }

  }
  useEffect(()=>{
    DemandesItems();
  },[])

  const handleChangePage = async (event, newPage) => {
    setPage(newPage);
    setLoading(true);
    try {
      const data = await getDemandes(newPage);
      setDemandes(data.certificate);
      setTotal(data.total);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
    getDemandes(newPage).then((data) => {
      setDemandes(data.certificate);
      setTotal(data.total);
    });
  };

  const handleChange = async (idDem) => {
   


    let data = {
      id:idDem,
      newStatus:"completed"
    }
    changeStatus(data)
    .then((data) => {
      const updatedDemandes = demandes.filter(demande => demande._id !== idDem);
      setDemandes(updatedDemandes);
        toast.success(" Opération Réussi");

    })
    .catch((error) => {
      console.error(error)
     })
  };

  return (
    <div>
       <Grid container className='bordHeader  mb-5'>
        <span style={{fontSize: '38px',fontWeight: 'bold',margin:'auto'}}>
        Demandes
        </span>
      
    </Grid>

      <TableContainer style={{ width: '80%',margin:'auto'}}  component={Paper}>
      <ToastContainer position="top-right" autoClose={3000} /> {/* You can customize options */}

        <Table>
          <TableHead>
            <TableRow>
                        <TableCell className='bordTableCell' align="center">Titre</TableCell>
                        <TableCell className='bordTableCell' align="center">N° Masar</TableCell>
                        <TableCell className='bordTableCell' align="center">Date</TableCell>
                        <TableCell className='bordTableCell' align="center">Actions</TableCell>

            </TableRow>
          </TableHead>
          <TableBody>
            {demandes?.length > 0 ? ( demandes.map((reqs,index) => (
              <TableRow key={index} 
              className='rowTableCellWait'
              style={{
                backgroundColor: 'rgb(5 61 102)',
                 height: '80px',
             }}
              
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
               <TableCell style={{maxWidth:'320px',wordWrap: 'break-word',color:'white'}} align="center" className='bordTableCell' component="th" scope="row">
                                    {reqs?.Title}
                                </TableCell>
                                <TableCell style={{maxWidth:'320px',wordWrap: 'break-word',color:'white'}}align="center" className="bordTableCell">{reqs?.MasarNumber}</TableCell>
                                <TableCell  style={{maxWidth:'320px',wordWrap: 'break-word',color:'white'}} align="center" className="bordTableCell">
                                    {reqs?.createdAt && reqs?.createdAt !== null ?
                                     (<div>
                                        < span style={{color:'white'}}><DateRange />  </span>
                                     <span>{moment(reqs?.createdAt).format('DD/MM/Y')} </span>
                                     <span>à {moment(reqs?.createdAt).format('HH:mm')} </span><br/>  
                                        </div> ) :'-'}
                                </TableCell>
                                 <TableCell align="center" className="bordTableCell" >
                                    <button  className='actionButton' onClick={() => handleChange(reqs?._id)}  >
                                        <span className='statusText' >Commancer</span>
                                        
                                    </button>
                                  
                                </TableCell>
                                 
                             
              </TableRow>
            ))
            ) : (
              <TableRow>
                <TableCell colSpan={4} align="center">
                {  loading ? null : 'Pas de données disponibles'}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        {loading ? (
          <div className="loading-container text-center">
            <CircularProgress size={30} />
           
          </div>
        ) : (
          total > 0 && (
            // <TablePagination
            //   rowsPerPageOptions={[10, 25, 50]}
            //   component="div"
            //   count={total}
            //   rowsPerPage={rowsPerPage}
            //   page={page}
            //   onPageChange={handleChangePage}
            //   onRowsPerPageChange={handleChangeRowsPerPage}
            // />
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
            <Typography>Page: {page + 1}</Typography>
             <Pagination
            count={Math.ceil(total / rowsPerPage)}
            page={page + 1}
            onChange={(event, newPage) => handleChangePage(event, newPage - 1)}
          />
          </div>
          )
        )}
      </TableContainer>

    </div>

  );
}

export default DemandeTable;

