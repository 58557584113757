import React, {useState ,useEffect} from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Pagination,
  Typography,
  Button,
  Grid,
} from '@mui/material';
import "../styles/owner/module.css"
import ModalComponent from '../components/Modal.component';
import {getStudents} from '../api/StudentsApi';
import {ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SearchIcon from '@mui/icons-material/Search';
import { fetchTransporters} from '../api/transporter';
import AssignTransporter from '../components/TransporterAssign';
import ExcludeTransport from '../components/ExcludeTransport';



function StudentsTransport() {
  const [students, setStudents] = useState([]);
  const [transporter, setTransporter] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(true);
  const [knumber, setKnumber] = useState('');
  const [fullName, setFullName] = useState('');
  const [openModal, setOpenModal] = useState('');
  const [passenger, setPassenger] = useState([]);
  const [passengerindex, setPassengerindex] = useState([]);


  const StudentsItems  = async ()=>{
    try {
      const data = await getStudents(1);
      setStudents(data.students);
      setTotal(data.total);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }

  }
  const getTransporters = async () => {
    try {
      const response = await fetchTransporters();
      setTransporter(response); 
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(()=>{
    getTransporters();
    StudentsItems();
  },[])
  const handleSearch = async () => {
    setLoading(true);
    try {
      const data = await getStudents(1,knumber,fullName);
      setStudents(data.students);
      setTotal(data.total);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setKnumber('');
      setFullName('');
      setLoading(false);
    }
  };
  
  

  const handleChangePage = async (event, newPage) => {
    setPage(newPage);
    setLoading(true);
    try {
      const data = await getStudents(1,'','',newPage);
      setStudents(data.students);
      setTotal(data.total);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
    getStudents(1,'','',newPage).then((data) => {
      setStudents(data.students);
      setTotal(data.total);
    });
  };
 
  const closeModal = () => {
    setOpenModal('');
  };
  const handleOpenModal = (item, index, type) => {
    setPassenger(item);
    setPassengerindex(index)
    setOpenModal(type);
}

  return (
    <div>
        <div>
        <Grid container className='bordHeader  mb-5'>
        <span style={{fontSize: '38px',fontWeight: 'bold',margin:'auto'}}>
          Passagers
        </span>
      
    </Grid>
        <div className="search-container">
<>
  <input type='text'  placeholder='N° etudiant' style={{height: '38px',
  border: '1px solid #ccc',
  padding: '8px',
  borderRadius: '4px'}}
  value={knumber} 
  onChange={(event) => setKnumber(event.target.value)}

  
  />
   <input type='text'  placeholder='Nom & Prénom' style={{height: '38px',
  border: '1px solid #ccc',
  padding: '8px',
  borderRadius: '4px'}}
  value={fullName} 
  onChange={(event) => setFullName(event.target.value)}

  
  />
</>
  

<Button
  variant="contained"
  startIcon={<SearchIcon />}
  style={{padding:'8px',
          width: '145px',
          border: 'none',
          borderRadius: '20px',
          backgroundColor: '#C81F1F',
          marginRight: '5px',
          color: 'white',}}
  onClick={()=>handleSearch()}
>
  Rechercher
</Button>
<Button
  variant="contained"
  // startIcon={<SearchIcon />}
  style={{padding:'8px',
          width: '145px',
          border: 'none',
          borderRadius: '20px',
          backgroundColor: 'rgb(19 113 181)',
          marginRight: '5px',
          color: 'white',}}
          onClick={() => {
            StudentsItems(1);

          }}
>
reload
</Button>
</div>
    </div>

      <TableContainer style={{ width: '80%',margin:'auto'}}  component={Paper}>
      <ToastContainer position="top-right" autoClose={3000} /> 

     
        <Table>
          <TableHead>
            <TableRow>
                        <TableCell className='bordTableCell' align="center">Nom & Prénom</TableCell>
                        <TableCell className='bordTableCell' align="center">N° Massar</TableCell>
                        <TableCell className='bordTableCell' align="center">Transport</TableCell>
                        <TableCell className='bordTableCell' align="center">Actions</TableCell>

            </TableRow>
          </TableHead>
          <TableBody>
            {students?.length > 0 ? (students.map((reqs,index) => (
              <TableRow key={index} 
              className='rowTableCellWait'
              style={{
                 backgroundColor: 'rgb(5 61 102)',
                  height: '80px',
              }}
              
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
               <TableCell style={{maxWidth:'320px',wordWrap: 'break-word',color:'white'}}align="center" className='bordTableCell' component="th" scope="row">
                                    {reqs?.FullName}
                                </TableCell>
                                <TableCell style={{maxWidth:'320px',wordWrap: 'break-word',color:'white'}} align="center" className="bordTableCell">{reqs?.masarNumber}</TableCell>
                                <TableCell style={{maxWidth:'320px',wordWrap: 'break-word',color:'white'}} align="center" className="bordTableCell">
                                {reqs?.transporteur ? (
              transporter?.find(t => t._id === reqs.transporteur)?.FullName
            ) : (
              '-'
            )}
                                  </TableCell>

                                <TableCell align="center" className="bordTableCell" >
                                    
                                    <button className='actionDetails' onClick={()=>handleOpenModal(reqs,index,'assignTranspoter')}  >
                                        <span className='statusText' >Assigner </span>
                                    </button>
                                    <button className='actionDetails'
                                    style={{
                                      backgroundColor: reqs?.transporteur ? '#0a97ff' : 'rgb(151, 151, 151)',
                                      important: 'true',
                                    }} disabled={!reqs?.transporteur}
                                    onClick={()=>handleOpenModal(reqs,index,'exclure')}  >
                                        <span className='statusText' >Exclure </span>
                                    </button>
                                </TableCell>
                                 
                             
              </TableRow>
             ))
             ) : (
               <TableRow>
                 <TableCell colSpan={4} align="center">
                 {  loading ? null : 'Pas de données disponibles'}
                 </TableCell>
               </TableRow>
             )}
          </TableBody>
        </Table>
        {loading ? (
          <div className="loading-container text-center">
            <CircularProgress size={30} />
           
          </div>
        ) : (
          total > 0 && (
            // <TablePagination
            //   rowsPerPageOptions={[10, 25, 50]}
            //   component="div"
            //   count={total}
            //   rowsPerPage={rowsPerPage}
            //   page={page}
            //   onPageChange={handleChangePage}
            //   onRowsPerPageChange={handleChangeRowsPerPage}
            // />
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
            <Typography>Page: {page + 1}</Typography>
             <Pagination
            count={Math.ceil(total / rowsPerPage)}
            page={page + 1}
            onChange={(event, newPage) => handleChangePage(event, newPage - 1)}
          />
          </div>
          )
        )}
      </TableContainer>


  
      {openModal && openModal === 'assignTranspoter' && (
        <ModalComponent
          maxWidth={false}
          close={closeModal}
          visible={openModal === 'assignTranspoter'}
          subTitle="Assigner Chauffeur"
        >
           <AssignTransporter
            dataStudent={passenger}
            close={closeModal}
            dataStudentindex={passengerindex}
            setStudents={setStudents}
            students={students} 
          />
        </ModalComponent>
      )}
  {openModal && openModal === 'exclure' && (
        <ModalComponent
          maxWidth={false}
          close={closeModal}
          visible={openModal === 'exclure'}
          subTitle="Exclus du transport"
        >          
          
          <ExcludeTransport
            dataStudent={passenger}
            close={closeModal}
            dataStudentindex={passengerindex}
            setStudents={setStudents}
            students={students} 
          />
        </ModalComponent>
      )}
    </div>
    
  );
}

export default StudentsTransport;

