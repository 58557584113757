import React, {useState ,useEffect} from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Pagination,
  Typography,
  Button,
  Grid,
} from '@mui/material';
import "../styles/owner/module.css"
import {getTeachers,deleteTeacher} from '../api/TeachersApi';
 import AddTeachersDialog from '../components/AddTeachersDialog';
import ModifyTeacherManDialog from '../components/ModifyTeacherDialog';
import AddIcon from '@mui/icons-material/Add'; 
import { toast,ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SearchIcon from '@mui/icons-material/Search';




function Teachers() {
  const [teachers, setTeachers] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const [openDialogM, setOpenDialogM] = useState(false);
  const [selectedTeacher, setSelectedTeacher] = useState(null);
  const [teacherIndex, setTeacherIndex] = useState(null);
  const [fullName, setFullName] = useState('');

  const TeachersItems  = async ()=>{
    try {
      const data = await getTeachers();
      setTeachers(data.Teachers);
      setTotal(data.total);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }

  }
  useEffect(()=>{
    TeachersItems();
  },[])

  
  const handleOpenDialog = () => {
    setOpenDialog(true);
  };
  const handleCloseDialogM = () => {
    setOpenDialogM(false);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleSearch = async () => {
    setLoading(true);
    try {
      const data = await getTeachers(fullName);
      setTeachers(data.Teachers);
      setTotal(data.total);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setFullName('');
      setLoading(false);
    }
  };
  
  const handleChangePage = async (event, newPage) => {
    setPage(newPage);
    setLoading(true);
    try {
      const data = await getTeachers('',newPage);
      setTeachers(data.Teachers);
      setTotal(data.total);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
    getTeachers('',newPage).then((data) => {
      setTeachers(data.Teachers);
      setTotal(data.total);
    });
  };
  const handleOpenDialogM = (data,index) => {
    setOpenDialogM(true);
    setSelectedTeacher(data);
    setTeacherIndex(index);
  };

  const handleDelete = async (student) => {
 
    deleteTeacher(student).then((response) => {
      if (response.status === 200) {
        getTeachers().then((data) => {
          setTeachers(data.Teachers);
          setTotal(data.total);
          toast.success("Suppression Réussie");

        });
        
      } else {
        console.error('Error deleting Event');
      }
    })
  };

  return (
    <div>
        <Grid container className='bordHeader  mb-5'>
        <span style={{fontSize: '38px',fontWeight: 'bold',margin:'auto'}}>
        Enseignants
        </span>
      
    </Grid>
    <div className="search-container">
<>
   <input type='text'  placeholder='Nom & Prénom' style={{height: '38px',
  border: '1px solid #ccc',
  padding: '8px',
  borderRadius: '4px'}}
  value={fullName} 
  onChange={(event) => setFullName(event.target.value)}

  
  />
</>
  

<Button
  variant="contained"
  startIcon={<SearchIcon />}
  style={{padding:'8px',
          width: '145px',
          border: 'none',
          borderRadius: '20px',
          backgroundColor: '#C81F1F',
          marginRight: '5px',
          color: 'white',}}
  onClick={()=>handleSearch()}
>
  Rechercher
</Button>
<Button
  variant="contained"
  // startIcon={<SearchIcon />}
  style={{padding:'8px',
          width: '145px',
          border: 'none',
          borderRadius: '20px',
          backgroundColor: 'rgb(19 113 181)',
          marginRight: '5px',
          color: 'white',}}
          onClick={() => {
            TeachersItems();

          }}
>
reload
</Button>
</div>
      <TableContainer style={{ width: '80%',margin:'auto'}}  component={Paper}>
      <ToastContainer position="top-right" autoClose={3000} /> 

      <Button startIcon={<AddIcon />} sx={{
                     background: '#c81f1f',  
                     float:'right',        // Customize the background color
                     color: 'white',  
                     margin:'10px',              // Customize the text color
                     '&:hover': {
                       background: '#ac1f1f',      // Customize the background color on hover
                     },
                   }}
                   variant="contained" color="primary" onClick={handleOpenDialog}> 
         Enseignant
      </Button>
        <Table>
          <TableHead>
            <TableRow>
                        <TableCell className='bordTableCell' align="center">Nom & Prénom</TableCell>
                        <TableCell className='bordTableCell' align="center">N° Tel</TableCell>
                        <TableCell className='bordTableCell' align="center">Mot de pass</TableCell>
                        <TableCell className='bordTableCell' align="center">Actions</TableCell>

            </TableRow>
          </TableHead>
          <TableBody>
            {teachers?.length > 0 ? ( teachers.map((reqs,index) => (
              <TableRow key={index} 
              className='rowTableCellWait'
              style={{
                 backgroundColor: 'rgb(5 61 102)',
                  height: '80px',
              }}
              
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
               <TableCell style={{maxWidth:'320px',wordWrap: 'break-word',color:'white'}}align="center" className='bordTableCell' component="th" scope="row">
                                    {reqs?.FullName}
                                </TableCell>
                                <TableCell style={{maxWidth:'320px',wordWrap: 'break-word',color:'white'}} align="center" className="bordTableCell">{reqs?.Phone}</TableCell>
                                <TableCell style={{maxWidth:'320px',wordWrap: 'break-word',color:'white'}} align="center" className="bordTableCell">{reqs?.decryptedPassword}</TableCell>

                                <TableCell align="center" className="bordTableCell" >
                                    <button style={{marginRight:'6px'}} className='actionButton'  onClick={() => handleDelete(reqs?._id)}  >
                                        <span className='statusText' >Supprimer</span>
                                        
                                    </button>
                                    <button className='actionDetails' onClick={() => handleOpenDialogM(reqs,index)} >
                                        <span className='statusText' >Modifier </span>
                                    </button>
                                </TableCell>
                                 
                             
              </TableRow>
             ))
            ) : (
              <TableRow>
                <TableCell colSpan={4} align="center">
             {  loading ? null : 'Pas de données disponibles'}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        {loading ? (
          <div className="loading-container text-center">
            <CircularProgress size={30} />
           
          </div>
        ) : (
          total > 0 && (
            // <TablePagination
            //   rowsPerPageOptions={[10, 25, 50]}
            //   component="div"
            //   count={total}
            //   rowsPerPage={rowsPerPage}
            //   page={page}
            //   onPageChange={handleChangePage}
            //   onRowsPerPageChange={handleChangeRowsPerPage}
            // />
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
            <Typography>Page: {page + 1}</Typography>
             <Pagination
            count={Math.ceil(total / rowsPerPage)}
            page={page + 1}
            onChange={(event, newPage) => handleChangePage(event, newPage - 1)}
          />
          </div>
          )
        )}
      </TableContainer>
      <AddTeachersDialog open={openDialog} onClose={handleCloseDialog}  teachers={TeachersItems}  /> 
      <ModifyTeacherManDialog open={openDialogM} onClose={handleCloseDialogM}  setTeachersData={setTeachers} teachersData={teachers} index={teacherIndex}  dataTeacher={selectedTeacher} />


    </div>

  );
}

export default Teachers;

