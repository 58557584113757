import React from "react";
import { createRoot } from 'react-dom/client';
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux"
import store from "../src/redux/store"
import axios from 'axios';


axios.interceptors.request.use((config) => {
  const reduxState = store.getState();
  // config.headers['Access-Control-Allow-Origin'] = '*';
  // config.headers['Access-Control-Allow-Methods'] = 'GET, POST, PUT, DELETE';
  // config.headers['Access-Control-Allow-Headers'] = 'Content-Type';
  // config.headers['Access-Control-Allow-Credentials'] = 'true';
  if (reduxState.authData?.admin) {
    if (!config.headers.Authorization) {
      config.headers.Authorization = `Bearer ${reduxState.authData?.admin.token}`;
    }
  }
  config.headers.isvita = true;
  return config;
});

axios.interceptors.response.use(
  (response) => response,
  async (error) => {
    // console.log('error', error);
    // if (error?.response?.status === 403) {
    //   showAlert();
    // }

    return Promise.reject(error);
  },
);
const rootElement = document.getElementById('root');
const root = createRoot(rootElement);
root.render(
<Provider store={store}>
  <React.StrictMode>
    <App />
  </React.StrictMode>
</Provider>
,);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
