import { endPoint } from "../constants/GlobaleVariables"
import axios from 'axios';


export const addTransporter = async (values) => {
    
    try {
        const response = await axios.post(`${endPoint}/api/transporters/addTranporter`, values);
        return response.data;
      } catch (error) {
        throw error.response.request
        // console.error('Error add Delivery Man', error);
      }

};


export const fetchTransporters= async () => {
  try {
    const response = await axios.get(`${endPoint}/api/transporters/getAllTrasporter`);
    return response.data;
  } catch (error) {
    throw error.response.request
    // console.error('Error fetching products:', error);
    // return [];
  }
};

export const getTransportersById = async (id) => {
  try {
    const response = await axios.get(`${endPoint}/api/transporters/getTransporter?transporteurId=${id}`);
    return response.data;
  } catch (error) {
    throw error.response.request
    // console.error('Error fetching products:', error);
    // return [];
  }
};


// export const searchDeliveryMan = async (text) => {
//   try {
//     const response = await axios.get(`${endPoint}/api/deliveryMan/search?searchText=${text}`);
//     return response.data;
//   } catch (error) {
//     console.error('Error fetching products:', error);
//     return [];
//   }
// };


export const deleteTransporter = async (deliveryManId) => {
  try {
    const response = await axios.delete(`${endPoint}/api/transporters/deleteTransporter/${deliveryManId}`);
    return response;
  } catch (error) {
    console.error('An error occurred while deleting the transporter:', error);
  }
};


export const updateTransporter = async (deliveryManId,updatedDeliveryMan) => {
  try {
    const response = await axios.put(`${endPoint}/api/transporters/updateTrasporter/${deliveryManId}`, updatedDeliveryMan);
    return response.data;
  } catch (error) {
    console.error('An error occurred while deleting the deliveryMa:', error);
  }
};


