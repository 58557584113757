const modalStyle = {
  modalRoot: {
    overflow: 'auto',
    alignItems: 'unset',
    justifyContent: 'unset',
  },
  modal: {
    borderRadius: '6px',
    overflow: 'visible',
    maxHeight: 'unset',
    position: 'relative',
    height: 'fit-content',
    minWidth: '300px',
    margin: 'auto', // Added this for centering
  },
  modalHeader: {
    borderBottom: 'none',
    paddingTop: '24px',
    paddingRight: '24px',
    paddingBottom: '0',
    paddingLeft: '24px',
    minHeight: '16.43px',
  },
  modalTitle: {
    lineHeight: '1.42857143 !important',
    borderBottom: '3px solid #3dc942',
    width: '39%',
    fontWeight: 'bold !important',
    fontSize:'25px !important',
    marginBottom: '15px',
  },
  subtitle: {
    lineHeight: '1.42857143 !important',
    borderBottom: '3px solid #3dc942',
    textAlign: 'center',
    fontWeight: 'bold !important',
    paddingBottom: '10px !important',
    fontSize:'30px !important',
    display:'inline'
  },
  modalCloseButton: {
    color: 'gray',
    marginTop: '-12px',
    WebkitAppearance: 'none',
    padding: '0',
    cursor: 'pointer',
    background: '0 0',
    border: '0',
    fontSize: 'inherit',
    opacity: '.9',
    textShadow: 'none',
    fontWeight: '700',
    lineHeight: '1',
    float: 'right',
  },
  modalClose: {
    width: '16px',
    height: '16px',
  },
  modalBody: {
    paddingRight: '24px',
    paddingBottom: '16px',
    paddingLeft: '24px',
    position: 'relative',
    overflow: 'auto',
    width: '100%',
    maxHeight: '60vh',
  },
  modalFooter: {
    padding: '15px',
    textAlign: 'right',
    paddingTop: '0',
    margin: '0',
  },
  modalFooterCenter: {
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  instructionNoticeModal: {
    marginBottom: '25px',
  },
  imageNoticeModal: {
    maxWidth: '150px',
  },
  modalSmall: {
    width: '300px',
  },
  modalSmallBody: {
    paddingTop: '0',
  },
  modalSmallFooterFirstButton: {
    margin: '0',
    paddingLeft: '16px',
    paddingRight: '16px',
    width: 'auto',
  },
  modalSmallFooterSecondButton: {
    marginBottom: '0',
    marginLeft: '5px',
  },
};

export default modalStyle;
