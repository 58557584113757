import React, {useState} from 'react'
import { Switch, Route } from "react-router-dom";
import SideMenu from '../components/owner/SideMenu';
import OwnerHeader from '../components/owner/OwnerHeader';
import  TransporterTable  from '../screens/TableTranporter';
import  EventsTable  from '../screens/Events';
import  Demande  from '../screens/demande';
import  Students  from '../screens/Students';
import  StudentsTransport  from '../screens/StudentsTransport';
import  DemandeOnWay  from '../screens/demandeOnWay';
import  Exams  from '../screens/Exams';
import  Admnotif  from '../screens/TeacherNotif';
import  Teachers  from '../screens/Teachers';

import {Redirect} from "react-router-dom";


function OwnerNavigation() {
    const [inactive, setInactive] = useState(false);

    

    return (

    <div className="home">
        <SideMenu 
            onCollapse={(inactive) => {
            setInactive(inactive);
        }}
        />

        <div className='containerApp'>
            <OwnerHeader />

            <div className="dashboardApp p-5" style={{
                overflow: 'auto',
                height: '93vh',
            }}>
            <Switch>

                <Route exact path={"/admin/etudiants"}> <Students /> </Route>
                <Route exact path={"/admin/etudiants_Transport"}> <StudentsTransport /> </Route>
                <Route exact path={"/admin/profs"}> <Teachers /> </Route>
                <Route exact path={"/admin/chauffeurs"}> <TransporterTable /> </Route>
                <Route exact path={"/admin/evenements"}> <EventsTable /> </Route>  
                <Route exact path={"/admin/exams"}> <Exams /> </Route>
                <Route exact path={"/admin/demandes_Encours"}> <Demande /> </Route>
                <Route exact path={"/admin/demandes"}> <DemandeOnWay /> </Route>
                <Route exact path={"/admin/adminis_notif"}> <Admnotif /> </Route>




                <Route path="*">
          <Redirect to="/admin/etudiants" />
            </Route>
            
                
                {/* <Route exact path="*"> <ErrorPage /> </Route> */}
            </Switch>
            </div>
        </div>

    </div>
   
    )
}

export default OwnerNavigation