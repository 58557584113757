import React ,{useState}from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import {addStudent} from '../api/StudentsApi'
import { toast } from 'react-toastify';
const validationSchema = Yup.object().shape({
  FullName: Yup.string().required('FullName is required'),
  masarNumber: Yup.string().required('N° Massar is required'),
  password: Yup.string().required('password is password'),
  transport: Yup.number(),


});

const AddStudentsDialog = ({ open, onClose, students }) => {
  const [eroroMessage,setEroroMessage]=useState('')
  const handleSubmit = async (values, { setSubmitting }) => {
    addStudent(values)
        .then((data) => {
          students();
            setSubmitting(false);
             onClose();
             toast.success(" Ajout Réussi");

        })
        .catch((error) => {
             setEroroMessage(JSON.parse(error.responseText))
        })
      

    
  };
 
  return (
    <Dialog PaperProps={{ style: { width: 500 }} } open={open} onClose={onClose} >

      <DialogTitle sx={{ padding: '30px',textAlign: 'center' }}>Ajouter Etudiant</DialogTitle>
      <DialogContent>
     { eroroMessage && <h5 className='text-center text-danger'>{eroroMessage?.error}</h5>}
      <Formik
        initialValues={{ FullName: '', masarNumber: '', password: ''}}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, setFieldValue }) => (
          <Form encType="multipart/form-data">
            <div className="mb-3">
              <label htmlFor="FullName" className="form-label">
                Nom & Prenom
              </label>
              <Field
                type="text"
                id="FullName"
                name="FullName"
                className="form-control"
                autoComplete="off"

              />
              <ErrorMessage name="FullName" component="div" className="text-danger" />
            </div>
            <div className="mb-3">
              <label htmlFor="masarNumber" className="form-label">
                N° Massar
              </label>
              <Field
                type="text"
                id="masarNumber"
                name="masarNumber"
                className="form-control"
                autoComplete="off"

              />
              <ErrorMessage name="masarNumber" component="div" className="text-danger" />
            </div>
            <div className="mb-3">
              <label htmlFor="password" className="form-label">
                Mot de pass
              </label>
              <Field
                type="text"
                id="password"
                name="password"
                className="form-control"
                autoComplete="off"

              />
              <ErrorMessage name="password" component="div" className="text-danger" />
            </div>
            <div className="mb-3">
                <label htmlFor="transport" className="form-label">
                Transport
                </label>
                <Field as="select" id="transport" name="transport" className="form-control" >
                <option  value={0} defaultValue> NON</option>
                <option  value={1}>OUI</option>
                </Field>
                <ErrorMessage name="transport" component="div" className="text-danger" />
              </div>
            
          
              <DialogActions>
                <Button onClick={onClose} color="secondary">
                  Annuler
                </Button>
                <Button type="submit" color="primary" disabled={isSubmitting}>
                  Ajouter
                </Button>
              </DialogActions>
              </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default AddStudentsDialog;

               