import React ,{useState}from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import {addTeacherNotif} from '../api/TeacherNotif'
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';
import "../styles/owner/module.css"
import moment from 'moment';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const validationSchema = Yup.object().shape({
  Title: Yup.string().required('Title is required'),
  Description: Yup.string().required('Description is required'),
  EventDate: Yup.string().required('Date is required'),


});

const AddEventDialog = ({ open, onClose,items}) => {
  const [date, setDate] = useState(null);

  const handleSubmit = async (values, { setSubmitting }) => {
    values.notif_title = "evénement";
    addTeacherNotif(values)
        .then((data) => {
          items()
          setDate()
           setSubmitting(false);
            onClose();
            toast.success(" Ajout Réussi");

        })
        .catch((error) => {
          console.error(error)
         })
      
    
  };
 
  return (
    <Dialog PaperProps={{ style: { width: 500 }} } open={open} onClose={onClose} >

      <DialogTitle sx={{ padding: '30px',textAlign: 'center' }}>Ajouter Evenement</DialogTitle>
      <DialogContent>
      <Formik
        initialValues={{ Title: '', Description: '', EventDate: ''}}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, setFieldValue }) => (
          <Form encType="multipart/form-data">
            <div className="mb-3">
              <label htmlFor="Title" className="form-label">
                Titre
              </label>
              <Field
                type="text"
                id="Title"
                name="Title"
                className="form-control"
                autoComplete="off"

              />
              <ErrorMessage name="Title" component="div" className="text-danger" />
            </div>
            <div className="mb-3">
                <label htmlFor="Description" className="form-label">
                  Description
                </label>
                <textarea 
                    onChange={(e) => {
                      setFieldValue('Description', e.target.value);
                    }}
                 rows="6" id="Description" name="Description" className="form-control" />
                <ErrorMessage name="Description" component="div" className="text-danger" />
              </div>
              <div className="mb-3">
                <label style={{display:'block'}} htmlFor="EventDate" className="form-label">
                  Date de l'événement
                </label>
                <DatePicker
                  label="Date de l'événement"
                  selected={date}
                  className="date-picker"
                  isClearable
                  placeholderText="Date"
                  onChange={(date) => {
                    setDate(date);
                    const startDates = date ? moment(date).format().split('+')[0].replace('T', ' ') : null;
                    setFieldValue('EventDate', startDates);
                  }}
                  showTimeSelect
                  timeIntervals={15} // Specify the time interval in minutes
                  dateFormat="dd/MM/yyyy HH:mm" // 
                />
                <ErrorMessage name="EventDate" component="div" className="text-danger" />
            </div>
            
          
              <DialogActions>
                <Button onClick={onClose} color="secondary">
                  Annuler
                </Button>
                <Button type="submit" color="primary" disabled={isSubmitting}>
                  Ajouter
                </Button>
              </DialogActions>
              </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default AddEventDialog;

               