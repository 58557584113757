import { endPoint } from "../constants/GlobaleVariables"
import axios from 'axios';


export const getStudents = async (status,knumber,fullName,page) => {
  try {
    const response = await axios.get(`${endPoint}/api/user/getUsers?status=${status || ''}&knumber=${knumber || ''}&fullName=${fullName || ''}&page=${page || 0}`);
    return response.data;
  } catch (error) {
    // console.error('Error fetching products:', error.response.data.message);
    return [];
  }
};


export const addStudent = async (values) => {
  try {
      const response = await axios.post(`${endPoint}/api/user/signup`, values);
      return response.data;
    } catch (error) {
      throw error.response.request
    }

};
export const deleteStudent = async (studentId) => {
  try {
    const response = await axios.delete(`${endPoint}/api/user/deleteStudent/${studentId}`);
    return response;
  } catch (error) {
    console.error('An error occurred while deleting the Student:', error);
  }
};


export const updateStudent = async (studentId,updatedStudent) => {
  try {
    const response = await axios.put(`${endPoint}/api/user/updateStudent/${studentId}`, updatedStudent);
    return response.data;
  } catch (error) {
    console.error('An error occurred while deleting the Student:', error);
  }
};

export const assignTranstudent = async (studentId,updatedStudent) => {
  try {
    const response = await axios.put(`${endPoint}/api/user/assignTranstudent/${studentId}`, updatedStudent);
    return response.data;
  } catch (error) {
    console.error('An error occurred while assign Transporter:', error);
  }
};
