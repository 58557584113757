import { endPoint } from "../constants/GlobaleVariables"
import axios from 'axios';


export const getDemandes = async (page) => {
  try {
    const response = await axios.get(`${endPoint}/api/certificates/getAllCertificates?page=${page || 0}&status=wait`);
    return response.data;
  } catch (error) {
    // console.error('Error fetching products:', error.response.data.message);
    return [];
  }
};


export const getDemandesComplete = async (knumber,page) => {
  try {
    const response = await axios.get(`${endPoint}/api/certificates/getAllCertificates?status=completed&knumber=${knumber ||''}&page=${page || 0}`);
    return response.data;
  } catch (error) {
    // console.error('Error fetching products:', error.response.data.message);
    return [];
  }
};

export const changeStatus = async (values) => {
  try {
      const response = await axios.put(`${endPoint}/api/certificates/changeStatus`, values);
      return response.data;
    } catch (error) {
      throw error.response.request
    }

};


export const deleteDemande = async (eventd) => {
  try {
    const response = await axios.delete(`${endPoint}/api/certificates/deleteCertificate/${eventd}`);
    return response;
  } catch (error) {
    console.error('An error occurred while deleting the transporter:', error);
  }
};

export const deleteAllDemandes = async () => {
  try {
    const response = await axios.delete(`${endPoint}/api/certificates/deleteAllCertificates`);
    return response;
  } catch (error) {
    console.error('An error occurred while deleting the transporter:', error);
  }
};