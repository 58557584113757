import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import {updateStudent} from '../api/StudentsApi'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const validationSchema = Yup.object().shape({
  FullName: Yup.string().required('Name is required'),
  masarNumber: Yup.string().required('Numéro Massar is required'),
  password: Yup.string().required('password is required'),
  transport : Yup.number().required('transport is required'),

});

const ModifyStudentManDialog = ({ open, onClose, setStudentsData,studentsData,index,dataStudent}) => {

  const handleSubmit = async (values, { setSubmitting }) => {
   
    updateStudent(dataStudent?._id,values).then((data) => {
      let newstudents = [...studentsData]
      newstudents[index] = data
      setStudentsData(newstudents)
      setSubmitting(false);
      onClose();
      toast.success("modification réussie");

     });
   
    
  };
  return (
    <Dialog PaperProps={{ style: { width: 500 }} } open={open} onClose={onClose} >

      <DialogTitle sx={{ padding: '30px',textAlign: 'center' }}>Modifier Chauffeurs</DialogTitle>
      <DialogContent>
      <Formik
        initialValues={{ FullName: dataStudent?.FullName, masarNumber: dataStudent?.masarNumber,password:dataStudent?.decryptedPassword,transport:dataStudent?.transport}}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, setFieldValue }) => (
          <Form >
            <div className="mb-3">
              <label htmlFor="masarNumber" className="form-label">
                N° Massar
              </label>
              <Field
                type="text"
                id="masarNumber"
                name="masarNumber"
                className="form-control"
              />
              <ErrorMessage name="masarNumber" component="div" className="text-danger" />
            </div>
            <div className="mb-3">
              <label htmlFor="FullName" className="form-label">
                Nom & Prénom
              </label>
              <Field
                type="text"
                id="FullName"
                name="FullName"
                className="form-control"
              />
              <ErrorMessage name="FullName" component="div" className="text-danger" />
            </div>
            <div className="mb-3">
              <label htmlFor="password" className="form-label">
                Mot de pass
              </label>
              <Field
                type="text"
                id="password"
                name="password"
                className="form-control"

              />
              <ErrorMessage name="password" component="div" className="text-danger" />
            </div>
            <div className="mb-3">
                <label htmlFor="transport" className="form-label">
                Transport
                </label>
                <Field as="select" id="transport" name="transport" className="form-control" >
                <option  value={0}> NON</option>
                <option  value={1}>OUI</option>
                </Field>
                <ErrorMessage name="transport" component="div" className="text-danger" />
              </div>
         

              <DialogActions>
                <Button onClick={onClose} color="secondary">
                  Annuler
                </Button>
                <Button type="submit" color="primary" disabled={isSubmitting}>
                  Ajouter
                </Button>
              </DialogActions>
              </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default ModifyStudentManDialog;

               