import { endPoint } from "../constants/GlobaleVariables"
import axios from 'axios';


export const getTeachers = async (fullName,page) => {
  try {
    const response = await axios.get(`${endPoint}/api/teacher/getAllTeachers?fullName=${fullName || ''}&page=${page || 0}`);
    return response.data;
  } catch (error) {
    // console.error('Error fetching products:', error.response.data.message);
    return [];
  }
};


export const addTeacher = async (values) => {
  try {
      const response = await axios.post(`${endPoint}/api/teacher/addTeacher`, values);
      return response.data;
    } catch (error) {
      throw error.response.request
    }

};
export const deleteTeacher = async (TeacherId) => {
  try {
    const response = await axios.delete(`${endPoint}/api/teacher/deleteTeacher/${TeacherId}`);
    return response;
  } catch (error) {
    console.error('An error occurred while deleting the Teacher:', error);
  }
};


export const updateTeacher = async (TeacherId,updatedTeacher) => {
  try {
    const response = await axios.put(`${endPoint}/api/teacher/updateTeacher/${TeacherId}`, updatedTeacher);
    return response.data;
  } catch (error) {
    console.error('An error occurred while deleting the Student:', error);
  }
};

