import React from 'react'
import { Box, Button } from '@mui/material';
import { assignTranstudent} from '../api/StudentsApi';
import {toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function ExcludeTransport({ dataStudent, close ,dataStudentindex,setStudents,students}) {
    const assign = () => {
        const dataAssign = { transport: 0}
        assignTranstudent(
            dataStudent?._id,
            dataAssign
        )
          .then((data) => {
            let newData = [...students];
    newData[dataStudentindex] = data?.student;
    newData = newData.filter(student => student.transport !== 0);
    setStudents(newData);
             close()
             toast.success("Exclus du transport");

          })
          .catch((error) => console.log(error));
    }
    const cancelModel = () => {
      close()
    }
    
    return (
        <div> 
     <h2 style={{textAlign:'center',fontWeight: 'bold'}}>Voulez-vous exclure {dataStudent?.FullName}?</h2>
     <Box display="flex" justifyContent="center" gap={2}>
      <Button variant="contained" style={{
                background: '#1bd105',
                color: 'white',
                padding:'10px 40px 10px 40px'
              }}
              onClick={()=>{assign()}}
              
              >
        Oui
      </Button>
      <Button variant="contained"  style={{
                background: 'none',
                border: '1px solid red',
                color: 'red',
                padding:'10px 40px 10px 40px'
              }}
              onClick={()=>{cancelModel()}}
              >
                 
        Non
      </Button>
    </Box>
          
        </div>
    )
}
