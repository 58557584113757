import React ,{useState}from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import {addExams} from '../api/Exams'
import 'react-datepicker/dist/react-datepicker.css';
import "../styles/owner/module.css"
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const validationSchema = Yup.object().shape({
  Title: Yup.string().required('Title is required'),
  driveLink: Yup.string().required('Link is required'),
  
});

const AddExamDialog = ({ open, onClose,items}) => {
  const [eroroMessage,setEroroMessage]=useState('')
  const handleSubmit = async (values, { setSubmitting }) => {
    if (!values.driveLink.includes('drive.google.com')) {
      setEroroMessage("Please enter a valid Drive link");
      return;
    }
    var url = values?.driveLink;
   var fileId = url.match(/\/file\/d\/(.+?)\//)[1];
    var finalUrl = "https://drive.google.com/uc?export=download&id=" + fileId;
    values.driveLink = finalUrl;
    values.notif_title = "exam";
    addExams(values)
        .then((data) => {
          items()
           setSubmitting(false);
            onClose();
            toast.success(" Ajout Réussi");

        })
        .catch((error) => {
          console.error(error)
         })
      
    
  };
 
  return (
    <Dialog PaperProps={{ style: { width: 500 }} } open={open} onClose={onClose} >

      <DialogTitle sx={{ padding: '30px',textAlign: 'center' }}>Ajouter Examen</DialogTitle>
      <DialogContent>
      { eroroMessage && <h5 className='text-center text-danger'>{eroroMessage}</h5>}

      <Formik
        initialValues={{ Title: '', driveLink: ''}}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, setFieldValue }) => (
          <Form encType="multipart/form-data">
            <div className="mb-3">
              <label htmlFor="Title" className="form-label">
                Titre
              </label>
              <Field
                type="text"
                id="Title"
                name="Title"
                className="form-control"
                autoComplete="off"

              />
              <ErrorMessage name="Title" component="div" className="text-danger" />
            </div>
            <div className="mb-3">
                <label htmlFor="driveLink" className="form-label">
                Lien
                </label>
                <textarea 
                    onChange={(e) => {
                      setFieldValue('driveLink', e.target.value);
                    }}
                 rows="6" id="driveLink" name="driveLink" className="form-control" />
                <ErrorMessage name="driveLink" component="div" className="text-danger" />
              </div>
        
              <DialogActions>
                <Button onClick={onClose} color="secondary">
                  Annuler
                </Button>
                <Button type="submit" color="primary" disabled={isSubmitting}>
                  Ajouter
                </Button>
              </DialogActions>
              </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default AddExamDialog;

               