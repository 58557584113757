import "./App.css";
import AppRoute from "./navigation/AppRoute";


function App() {

  return (
    <div className="App">
      <AppRoute />
    </div>
  );

}

export default App;





