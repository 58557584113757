import React, {useState ,useEffect} from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Pagination,
  Typography,
  Button,
  Grid,
} from '@mui/material';
import "../styles/owner/module.css"
import { getEvents,deleteEvent } from '../api/Events';
import AddIcon from '@mui/icons-material/Add'; 
import AddEventDialog from '../components/AddEventDialog';
import { DateRange } from '@mui/icons-material';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



function EventsTable() {
  const [events, setEvents] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);

  const eventsItems  = async ()=>{
    try {
      const data = await getEvents();
      setEvents(data.eventsPrograme);
      setTotal(data.total);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }

  }
  useEffect(()=>{
  eventsItems();
  },[])

  
  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };


  const handleChangePage = async (event, newPage) => {
    setPage(newPage);
    setLoading(true);
    try {
      const data = await getEvents(newPage);
      setEvents(data.eventsPrograme);
      setTotal(data.total);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
    getEvents(newPage).then((data) => {
      setEvents(data.eventsPrograme);
      setTotal(data.total);
    });
  };

  const handleDelete = async (eventId) => {
 
    deleteEvent(eventId).then((response) => {
      if (response.status === 200) {
        getEvents().then((data) => {
          setEvents(data.eventsPrograme);
          setTotal(data.total);
          toast.success("Suppression Réussie");

        });
        
      } else {
        console.error('Error deleting Event');
      }
    })
  };

  return (
    <div>
      
      <Grid container className='bordHeader  mb-5'>
        <span style={{fontSize: '38px',fontWeight: 'bold',margin:'auto'}}>
        Evenements
        </span>
      
    </Grid>
      <TableContainer style={{ width: '80%',margin:'auto'}}  component={Paper}>
   
      <ToastContainer position="top-right" autoClose={3000} /> {/* You can customize options */}

      <Button startIcon={<AddIcon />} sx={{
                     background: '#c81f1f',  
                     float:'right',        // Customize the background color
                     color: 'white',  
                     margin:'10px',              // Customize the text color
                     '&:hover': {
                       background: '#ac1f1f',      // Customize the background color on hover
                     },
                   }}
                   variant="contained" color="primary" onClick={handleOpenDialog}> 
         Evenement
      </Button>
        <Table>
          <TableHead>
            <TableRow>
                        <TableCell className='bordTableCell' align="center">Titre</TableCell>
                        <TableCell className='bordTableCell' align="center">Description</TableCell>
                        <TableCell className='bordTableCell' align="center">Date</TableCell>
                        <TableCell className='bordTableCell' align="center">Actions</TableCell>

            </TableRow>
          </TableHead>
          <TableBody>
            {events?.length > 0 ? (  events.map((event,index) => (
              <TableRow key={index} 
              className='rowTableCellWait'
              style={{
                backgroundColor: 'rgb(5 61 102)',
                 height: '80px',
             }}
              
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
               <TableCell align="center" style={{maxWidth:'320px',wordWrap: 'break-word',color:'white'}} className='bordTableCell' component="th" scope="row">
                                    {event?.Title}
                                </TableCell>
                                <TableCell style={{maxWidth:'320px',wordWrap: 'break-word',color:'white'}} align="center" className="bordTableCell">{event?.Description}</TableCell>
                                 <TableCell style={{maxWidth:'320px',wordWrap: 'break-word',color:'white'}} align="center" className="bordTableCell"> < span style={{color:'white'}}><DateRange />  </span>{event?.EventDate}</TableCell>
                                 <TableCell align="center" className="bordTableCell" >
                                    <button  className='actionButton' onClick={() => handleDelete(event._id)}  >
                                        <span className='statusText' >Supprimer</span>
                                        
                                    </button>
                                  
                                </TableCell>
                                 
                             
              </TableRow>
            ))
            ) : (
              <TableRow>
                <TableCell colSpan={4} align="center">
                {  loading ? null : 'Pas de données disponibles'}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        {loading ? (
          <div className="loading-container text-center">
            <CircularProgress size={30} />
           
          </div>
        ) : (
          total > 0 && (
            // <TablePagination
            //   rowsPerPageOptions={[10, 25, 50]}
            //   component="div"
            //   count={total}
            //   rowsPerPage={rowsPerPage}
            //   page={page}
            //   onPageChange={handleChangePage}
            //   onRowsPerPageChange={handleChangeRowsPerPage}
            // />
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
            <Typography>Page: {page + 1}</Typography>
             <Pagination
            count={Math.ceil(total / rowsPerPage)}
            page={page + 1}
            onChange={(event, newPage) => handleChangePage(event, newPage - 1)}
          />
          </div>
          )
        )}
      </TableContainer>
      <AddEventDialog open={openDialog} onClose={handleCloseDialog}  items={eventsItems}  />

    </div>

  );
}

export default EventsTable;

