import React ,{useState}from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import {addTransporter} from '../api/transporter'
import {toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const validationSchema = Yup.object().shape({
  FullName: Yup.string().required('FullName is required'),
  Phone: Yup.number().required('phone is required'),
  password: Yup.string().required('phone is password'),


});

const AddDeliveryManDialog = ({ open, onClose, addTrans }) => {
  const [eroroMessage,setEroroMessage]=useState('')
  const handleSubmit = async (values, { setSubmitting }) => {

    addTransporter(values)
        .then((data) => {
          addTrans(data)
            setSubmitting(false);
             onClose();
             toast.success(" Ajout Réussi");

        })
        .catch((error) => {
             setEroroMessage(JSON.parse(error.responseText))
        })
      
   
    // addDeliveryMan(values).then((data) => addDelivery(data) );
    //  setSubmitting(false);
    //  onClose();
    
  };
 
  return (
    <Dialog PaperProps={{ style: { width: 500 }} } open={open} onClose={onClose} >

      <DialogTitle sx={{ padding: '30px',textAlign: 'center' }}>Ajouter Chauffeur</DialogTitle>
      <DialogContent>
     { eroroMessage && <h5 className='text-center text-danger'>{eroroMessage?.error}</h5>}
      <Formik
        initialValues={{ name: '', phone: '', password: ''}}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, setFieldValue }) => (
          <Form encType="multipart/form-data">
            <div className="mb-3">
              <label htmlFor="FullName" className="form-label">
                Nom & Prenom
              </label>
              <Field
                type="text"
                id="FullName"
                name="FullName"
                className="form-control"
                autoComplete="off"

              />
              <ErrorMessage name="FullName" component="div" className="text-danger" />
            </div>
            <div className="mb-3">
              <label htmlFor="phone" className="form-label">
                Mot de pass
              </label>
              <Field
                type="text"
                id="password"
                name="password"
                className="form-control"
                autoComplete="off"

              />
              <ErrorMessage name="password" component="div" className="text-danger" />
            </div>
            <div className="mb-3">
              <label htmlFor="Phone" className="form-label">
                Tél
              </label>
              <Field
                type="tel"
                id="Phone"
                name="Phone"
                className="form-control"
                autoComplete="off"

              />
              <ErrorMessage name="Phone" component="div" className="text-danger" />
            </div>
            
          
              <DialogActions>
                <Button onClick={onClose} color="secondary">
                  Annuler
                </Button>
                <Button type="submit" color="primary" disabled={isSubmitting}>
                  Ajouter
                </Button>
              </DialogActions>
              </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default AddDeliveryManDialog;

               