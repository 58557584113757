import React, { useState, useEffect } from 'react';
import {Grid,Table,TableCell,TableRow,TableBody,TableHead,TableContainer,CircularProgress,Button,Paper} from '@mui/material';
import AddTransporterDialog  from '../components/AddTransporterDialog';
import "../styles/owner/module.css"
import AddIcon from '@mui/icons-material/Add'; 
import { fetchTransporters,deleteTransporter} from '../api/transporter';
import ModifyTransporterManDialog  from '../components/ModifyTransporterManDialog';
import { makeStyles } from '@mui/styles';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const useStyles = makeStyles((theme) => ({
  // tableContainer: {
  //     maxHeight: 600,
  //     overflowY: 'auto',
  // },
  customScrollbarContent: {
      '&::-webkit-scrollbar': {
          width: 12, /* Width of the vertical scrollbar */
      },
      '&::-webkit-scrollbar-thumb': {
          backgroundColor: 'darkgray', /* Color of the scrollbar thumb */
          borderRadius: 4,
      },
      '&::-webkit-scrollbar-track': {
          backgroundColor: 'lightgray', /* Color of the scrollbar track */
      },
  },
}));
const TransporterTable = () => {
  const [deliveryMan, setDeliveryMan] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [openDialogM, setOpenDialogM] = useState(false);
  const [selectedDeliveryMan, setSelectedDeliveryMan] = useState(null);
  const [deliveryManIndex, setDeliveryManIndex] = useState(null);
  const [loading, setLoading] = useState(true);

  const classes = useStyles();


  useEffect(() => {
    
    fetchTransporters()
    .then((data) => {
      setDeliveryMan(data);
    })
    .catch((error) => {
      console.error('Error fetching data:', error);
    }).finally(()=>{
      setLoading(false);

    });
  }, [deliveryMan.phone]);

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const addTrans= (delivery) => {
    setDeliveryMan([...deliveryMan, delivery]);
    handleCloseDialog();
  };
  const handleCloseDialogM = () => {
    setOpenDialogM(false);
  };
  const handleOpenDialogM = (categorie,index) => {
    setOpenDialogM(true);
    setSelectedDeliveryMan(categorie);
    setDeliveryManIndex(index);
  };
  
  const handleDelete = async (deliveryId) => {
 
    deleteTransporter(deliveryId).then((response) => {
      if (response.status === 200) {
        fetchTransporters().then((data) => setDeliveryMan(data));
        toast.success("Suppression Réussie");

      } else {
        console.error('Error deleting transporter');
      }
    })
  };

  return (
  

    <Grid  className='bordGlobalContainer'>
            <ToastContainer position="top-right" autoClose={3000} /> 

    <Grid container className='bordHeader mb-5'>
        <span style={{fontSize: '38px',fontWeight: 'bold',margin:'auto'}}>
        Les chauffeurs
        </span>
      
    </Grid>
            <TableContainer className={classes.customScrollbarContent} component={Paper}>
    

         <>
      
         <Button startIcon={<AddIcon />} sx={{
                     background: '#c81f1f',  
                     float:'right',        // Customize the background color
                     color: 'white',  
                     margin:'10px',              // Customize the text color
                     '&:hover': {
                       background: '#ac1f1f',      // Customize the background color on hover
                     },
                   }}
                   variant="contained" onClick={handleOpenDialog} color="primary">
         Chauffeur
      </Button>
                <Table
                sx={{ minWidth: 650 }}
                aria-label="simple table"
                stickyHeader
                className='bordTableGlobal'
                style={{
                    border: 'none',
                    borderCollapse: 'separate',
                    borderSpacing: '0px 5px',
                }}
                >
                  

                  <TableHead>
          <TableRow>
            <TableCell className='bordTableCell' align="center">Nom</TableCell>
            <TableCell className='bordTableCell' align="center">Tél</TableCell>
            <TableCell className='bordTableCell' align="center">Mot de passe</TableCell>
            <TableCell className='bordTableCell' align="center">Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {loading ? (
            
                <TableRow>
                <TableCell colSpan={4} align="center">
                <div className="loading-container text-center mx-auto">
              <CircularProgress size={30} />
            </div>               
             </TableCell>
              </TableRow>
          ) : (
            deliveryMan?.length > 0 ? (
              deliveryMan.map((trans, index) => (
                <TableRow
                  className='rowTableCellWait'
                  style={{
                    backgroundColor: 'rgb(5 61 102)',
                    height: '80px',
                  }}
                  key={index}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell style={{ maxWidth: '320px', wordWrap: 'break-word', color: 'white' }} align="center" className="bordTableCell">{trans?.FullName} </TableCell>
                  <TableCell style={{ maxWidth: '320px', wordWrap: 'break-word', color: 'white' }} align="center" className="bordTableCell">{trans?.Phone} </TableCell>
                  <TableCell style={{ maxWidth: '320px', wordWrap: 'break-word', color: 'white' }} align="center" className="bordTableCell">{trans?.decryptedPassword} </TableCell>
                  <TableCell align="center" className="bordTableCell">
                    <button style={{ marginRight: '6px' }} className='actionButton' onClick={() => handleDelete(trans._id)}>
                      <span className='statusText'>Supprimer</span>
                    </button>
                    <button className='actionDetails' onClick={() => handleOpenDialogM(trans, index)}>
                      <span className='statusText'>Modifier </span>
                    </button>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={4} align="center">
                  {loading ? null : 'Pas de données disponibles'}
                </TableCell>
              </TableRow>
            )
          )}
        </TableBody>
      </Table>
                </> 
            </TableContainer>
   
    <AddTransporterDialog open={openDialog} onClose={handleCloseDialog} addTrans={addTrans} />
   <ModifyTransporterManDialog open={openDialogM} onClose={handleCloseDialogM}  setDeliveryMan={setDeliveryMan} deliveryMan={deliveryMan} index={deliveryManIndex}  dataDeliveryMan={selectedDeliveryMan} />



  
</Grid >
  );
};

export default TransporterTable;
