import React, {useState ,useEffect} from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Pagination,
  Typography,
  Button,
  Grid,
} from '@mui/material';
import "../styles/owner/module.css";
import SearchIcon from '@mui/icons-material/Search';
import { getDemandesComplete, deleteDemande} from '../api/Demande';
import moment from 'moment';
import { DateRange } from '@mui/icons-material';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function DemandeOnWayTable() {
  const [demandes, setDemandes] = useState([]);
  const [knumber, setKnumber] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(true);

  const DemandesItems  = async ()=>{
    try {
      const data = await getDemandesComplete();
      setDemandes(data.certificate);
      setTotal(data.total);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }

  }
  useEffect(()=>{
    DemandesItems();
  },[])
  const handleSearch = async () => {
    setLoading(true);
    try {
      const data = await getDemandesComplete(knumber);
      setDemandes(data.certificate);
      setTotal(data.total);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setKnumber('')
      setLoading(false);
    }
  };
  const handleChangePage = async (event, newPage) => {
    setPage(newPage);
    setLoading(true);
    try {
      const data = await getDemandesComplete(newPage);
      setDemandes(data.certificate);
      setTotal(data.total);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
    getDemandesComplete(newPage).then((data) => {
      setDemandes(data.certificate);
      setTotal(data.total);
    });
  };

  const handleDelete = async (eventId) => {
 
    deleteDemande(eventId).then((response) => {
      if (response.status === 200) {
        getDemandesComplete().then((data) => {
          setDemandes(data.certificate);
          setTotal(data.total);       
           toast.success("Suppression Réussie");
        });
        
      } else {
        console.error('Error deleting Event');
      }
    })
  };

  return (
    <div>
       <Grid container className='bordHeader  mb-5'>
        <span style={{fontSize: '38px',fontWeight: 'bold',margin:'auto'}}>
        Demandes
        </span>
      
    </Grid>
        <div>
    <div className="search-container">
<div>
  <input type='text'  placeholder='N° etudiant' style={{height: '38px',
  border: '1px solid #ccc',
  padding: '8px',
  borderRadius: '4px'}}
  value={knumber} 
  onChange={(event) => setKnumber(event.target.value)}

  
  />
</div>
  

<Button
  variant="contained"
  startIcon={<SearchIcon />}
  style={{padding:'8px',
          width: '145px',
          border: 'none',
          borderRadius: '20px',
          backgroundColor: '#C81F1F',
          marginRight: '5px',
          color: 'white',}}
  onClick={()=>handleSearch()}
>
  Rechercher
</Button>
<Button
  variant="contained"
  // startIcon={<SearchIcon />}
  style={{padding:'8px',
          width: '145px',
          border: 'none',
          borderRadius: '20px',
          backgroundColor: 'rgb(19 113 181)',
          marginRight: '5px',
          color: 'white',}}
          onClick={() => {
            getDemandesComplete();

          }}
>
reload
</Button>
</div>
    </div>

      <TableContainer style={{ width: '80%',margin:'auto'}}  component={Paper}>
      <ToastContainer position="top-right" autoClose={3000} /> {/* You can customize options */}

        <Table>
          <TableHead>
            <TableRow>
                        <TableCell className='bordTableCell' align="center">Titre</TableCell>
                        <TableCell className='bordTableCell' align="center">N° Masar</TableCell>
                        <TableCell className='bordTableCell' align="center">Date</TableCell>
                        <TableCell className='bordTableCell' align="center">Actions</TableCell>

            </TableRow>
          </TableHead>
          <TableBody>
            {demandes?.length > 0 ? (  demandes.map((reqs,index) => (
              <TableRow key={index} 
              className='rowTableCellWait'
              style={{
                backgroundColor: 'rgb(5 61 102)',
                 height: '80px',
             }}
              
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
               <TableCell style={{maxWidth:'320px',wordWrap: 'break-word',color:'white'}} align="center" className='bordTableCell' component="th" scope="row">
                                    {reqs?.Title}
                                </TableCell>
                                <TableCell style={{maxWidth:'320px',wordWrap: 'break-word',color:'white'}} align="center" className="bordTableCell">{reqs?.MasarNumber}</TableCell>
                                <TableCell style={{maxWidth:'320px',wordWrap: 'break-word',color:'white'}}align="center" className="bordTableCell">
                                    {reqs?.createdAt && reqs?.createdAt !== null ?
                                     (<div>
                                        < span style={{color:'white'}}><DateRange />  </span>
                                     <span>{moment(reqs?.createdAt).format('DD/MM/Y')} </span>
                                     <span>à {moment(reqs?.createdAt).format('HH:mm')} </span><br/>  
                                        </div> ) :'-'}
                                </TableCell>
                                 <TableCell align="center" className="bordTableCell" >
                                    <button  className='actionButton' onClick={() => handleDelete(reqs._id)}  >
                                        <span className='statusText' >Supprimer</span>
                                        
                                    </button>
                                  
                                </TableCell>
                                 
                             
              </TableRow>
          ))
          ) : (
            <TableRow>
              <TableCell colSpan={4} align="center">
              {  loading ? null : 'Pas de données disponibles'}           
                 </TableCell>
            </TableRow>
          )}
          </TableBody>
        </Table>
        {loading ? (
          <div className="loading-container text-center">
            <CircularProgress size={30} />
           
          </div>
        ) : (
          total > 0 && (
            // <TablePagination
            //   rowsPerPageOptions={[10, 25, 50]}
            //   component="div"
            //   count={total}
            //   rowsPerPage={rowsPerPage}
            //   page={page}
            //   onPageChange={handleChangePage}
            //   onRowsPerPageChange={handleChangeRowsPerPage}
            // />
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
            <Typography>Page: {page + 1}</Typography>
             <Pagination
            count={Math.ceil(total / rowsPerPage)}
            page={page + 1}
            onChange={(event, newPage) => handleChangePage(event, newPage - 1)}
          />
          </div>
          )
        )}
      </TableContainer>

    </div>

  );
}

export default DemandeOnWayTable;

